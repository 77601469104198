/* Built In Imports */
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Checkbox,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { getCookie } from '@components/Utility/utils';
import Cookies from 'js-cookie';
import { useContextualRouting } from 'next-use-contextual-routing';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';

/* Internal Imports */
/* Components */
import UpcomingProgramCard from '@components/Card/UpcomingProgramCard';
import SectionTitle from '@components/Headings/SectionTitle';
import Loader from '@components/Utility/Loader';
import config from '@config';
import ProgramDataUI from './ProgramDataUI';
import StructuredTextContent from './StructuredTextContent';

/* Services */

/**
 * Renders the Upcoming Programs V2 component.
 *
 * @param {object} sectionData - content from api
 * @param {object} languageData - language
 * @param sectionData.sectionContent
 * @param {string} region - region and country
 * @param sectionData.region
 * @param sectionData.language
 * @param sectionData.isLeftMenu
 * @param sectionData.apiType
 * @returns {ReactElement} UpcomingProgramsV2 component.
 */

const UpcomingProgramsV2 = ({
  sectionContent,
  region,
  language,
  isLeftMenu,
  apiType,
}) => {
  sectionContent.titleDecoration = 'flowers';
  const router = useRouter();
  const { returnHref } = useContextualRouting();

  const [loading, setLoading] = useState(false);
  const [resultsCount, setResultCount] = useState(0);
  let [isSearch, setIsSearch] = useState(1);
  const [programs, setPrograms] = useState([]);
  // const [nearUser, setNearUser] = useState(false);
  const [rangeStart, setRangeStart] = useState(0); // default
  const [programFilter, setProgramFilter] = useState('');
  const [lang, setLang] = useState(sectionContent?.defaultLanguageValue || ''); // ? confirm if its defaultLanguageValue only, its a guess based on location
  const [center, setCenter] = useState(
    sectionContent?.defaultTypeOfLocation === 'center'
      ? sectionContent?.defaultLocationValue.trim()
      : ''
  );
  const [search, setSearch] = useState('');
  const [loadtext, setLoadtext] = useState('Load More');
  const searchBoxRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isChecked, setIsChecked] = useState(false);
  const [locationPermission, setLocationPermission] = useState(null);
  const [locationVal, setLocationVal] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const cookieChkbxValue = getCookie('chkbox_nearme');
  const cookieValue = getCookie('g_latlong');
  const cookieAllowValue = getCookie('access_deny');

  const count = 12; // asks for 12 results
  let currentUrl = router.asPath;
  const scrollToView = () => {
    if (
      currentUrl.includes('adiyogi-abode-of-yoga/processess-and-offerings/')
    ) {
      if (typeof window !== 'undefined') {
        const upcomingProgramsElement =
          document.getElementById('upcomingprograms');
        upcomingProgramsElement.scrollIntoView();
      }
    }
  };
  useEffect(() => {
    scrollToView();
  }, []);

  const buildUrl = async () => {
    // try object entries for api controlled queries
    let urlObj = {
      option: 'com_program',
      v: 2,
      format: 'json',
      task: 'filter',
      count: count,
      // category: sectionContent.defaultProgramCategoryId,
      startrec: rangeStart,
      // type: programFilter,
      // language: lang,
      // center: center,
      // radius: sectionContent?.radius,
    };
    if (locationVal) {
      urlObj.latlong = locationVal;
      urlObj.near_user = '';
    }
    if (!apiType && sectionContent?.defaultProgramCategoryId) {
      urlObj.category = sectionContent?.defaultProgramCategoryId;
    }
    if (programFilter) {
      if (apiType === 'macro') {
        urlObj.category = programFilter;
      } else {
        urlObj.type = programFilter;
      }
    }
    if (lang) {
      urlObj.language = lang;
    }

    if (sectionContent?.radius) {
      urlObj.radius = sectionContent?.radius;
    }
    if (
      sectionContent?.defaultTypeOfLocation &&
      cookieValue == undefined &&
      cookieChkbxValue != 'true'
    ) {
      urlObj[sectionContent?.defaultTypeOfLocation] =
        sectionContent?.defaultTypeOfLocation === 'country'
          ? sectionContent?.defaultLocationValue?.toUpperCase().trim()
          : sectionContent?.defaultLocationValue.trim();
    }
    if (center) {
      urlObj.center = center;
      urlObj.near_user = '';
      // urlObj.near_user = null;
    }
    if (search?.length > 1) {
      if (apiType === 'macro') {
        urlObj.city = search;
      } else {
        urlObj.search = search;
      }
      urlObj.near_user = '';
    }
    return `${
      apiType === 'macro'
        ? config.HATHA_YOGA_PROGRAM_API_PATH
        : config.SCHEDULE_API_PATH
      // : 'https://api.ishafoundation.org/scheduleApi/api.php'
    }?${qs.stringify(urlObj).replace('%252C', ',').replace('%2C', ',')}`;
  };

  const getProgramList = useCallback(async () => {
    setLoading(true);
    setLoadtext('Loading...');
    const url = await buildUrl();
    let programList = await axios.get(url);
    programList = programList?.data;
    // consoleLog('macro', apiType, programList);
    if (apiType === 'macro') {
      programList.results = handleData(programList);
      // programList.results = programList.results.splice(rangeStart, 12);
      // consoleLog('programList', rangeStart, programList);
    }
    setResultCount(programList?.resultcount);
    setLoadtext('Load More');
    if (programList?.results) {
      setPrograms(prev => [...prev, ...programList?.results]);
    }
    setLoading(false);
  }, [
    region,
    language,
    sectionContent,
    rangeStart,
    programFilter,
    lang,
    center,
    search,
    locationVal,
  ]);

  const handleData = data => {
    let finalData = [];
    let counter = 0;
    // for (let i = 0; i < 50; i++) {
    while (data?.results[programs.length + counter + 1]) {
      // consoleLog('data?.results[0][counter + 1]', data?.results[counter + 1], programs.length);
      // if (data?.results[counter + 1]) {
      let tempData = {};
      let index = `${counter + 1}`;
      tempData[index] = data.results[programs.length + counter + 1];
      finalData.push(tempData);
      // }
      counter++;
    }
    // consoleLog('handleData', data, finalData);
    return finalData;
  };

  const handleLoadMore = e => {
    e.preventDefault();
    console.log(
      'NoOfResultdislayed',
      programs?.length,
      'ResultCount',
      resultsCount,
      'Count',
      count
    );
    setRangeStart(prev => prev + count); // adds 12 more values
  };

  const handleCheckboxChange = e => {
    setIsChecked(!isChecked);
    if (e.target.checked == false) {
      uncheck_nearme();
      setResultCount(0);
      setPrograms([]);
      setRangeStart(0);
      // setLang('');
    } else if (
      e.target.checked == true ||
      (cookieChkbxValue == 'true' && cookieAllowValue != 'AccessDenied')
    ) {
      Cookies.set('access_deny', '', 0, '/');
      Cookies.set('chkbox_nearme', true, 0, '/');
      if (cookieValue != undefined) {
        setCenter('');
        setSearch('');
        // setLang('');
        setResultCount(0);
        setPrograms([]);
        setRangeStart(0);
        setLocationVal(cookieValue);
      } else {
        requestLocationPermission();
      }
    } else if (
      (locationPermission == 'denied' && cookieChkbxValue == true) ||
      cookieChkbxValue == false
    ) {
      Cookies.set('access_deny', 'AccessDenied', 0, '/');
      showPopupWithTimeout();
      getProgramList();
    }
  };
  const uncheck_nearme = () => {
    Cookies.set('chkbox_nearme', false, 0, '/');
    setIsChecked(false);
    setLocationVal('');
    return;
  };
  const handleProgramTypeFilter = e => {
    uncheck_nearme();
    // e.preventDefault();
    setResultCount(0);
    setPrograms([]);
    setRangeStart(0);
    setProgramFilter(e.value); // ? need to see if this works.
  };

  const handleLangFilter = e => {
    // e.preventDefault();
    setResultCount(0);
    setPrograms([]);
    setRangeStart(0);
    setLang(e.value);
  };

  const handleCenterFilter = e => {
    uncheck_nearme();
    // e.preventDefault();
    setSearch('');
    setLang('');
    setResultCount(0);
    setPrograms([]);
    setRangeStart(0);
    setCenter(e.value);
  };

  const handleSearchFilter = e => {
    uncheck_nearme();
    e.preventDefault();
    setCenter('');
    setResultCount(0);
    setPrograms([]);
    setRangeStart(0);
    setIsSearch(isSearch + 1);
    setSearch(e.target.value || searchBoxRef?.current?.value);
    // setSearch(e.target.value);
  };

  useEffect(() => {
    if (isChecked) {
      if (cookieChkbxValue && locationVal) {
        getProgramList();
      } else if (
        cookieChkbxValue == 'true' &&
        cookieAllowValue == 'AccessDenied'
      ) {
        setIsChecked(true);
        showPopupWithTimeout();
        getProgramList();
      }
    } else if (cookieChkbxValue == undefined || cookieChkbxValue == 'false') {
      getProgramList();
    } else if (cookieChkbxValue == 'true' && cookieValue != undefined) {
      setIsChecked(true);
      setCenter('');
      setSearch('');
      setPrograms([]);
      setRangeStart(0);
      setLocationVal(cookieValue);
    }
  }, [getProgramList, isSearch, cookieChkbxValue, locationVal]);

  const checkLocationPermission = async () => {
    const status = await navigator.permissions.query({ name: 'geolocation' });
    setLocationPermission(status.state);
  };
  const requestLocationPermission = async () => {
    if (cookieValue === undefined) {
      navigator.geolocation.getCurrentPosition(
        position => {
          var cookieGeoLatLng = `${position.coords.latitude},${position.coords.longitude}`;
          Cookies.set('g_latlong', cookieGeoLatLng, {
            expires: (24 / 1440) * 60,
          });
          setLocationVal(cookieGeoLatLng);
          setCenter('');
          setSearch('');
          // setLang('');
          setPrograms([]);
          setRangeStart(0);
        },
        error => {
          Cookies.set('access_deny', 'AccessDenied', 0, '/');
          showPopupWithTimeout();
          getProgramList();
        }
      );
    }
    return;
  };
  if (
    sectionContent?.defaultTypeOfLocation === 'near_user' &&
    sectionContent?.defaultLocationValue == 'true' &&
    locationPermission == 'prompt' &&
    cookieChkbxValue == 'true'
  ) {
    requestLocationPermission();
  } else if (
    sectionContent?.defaultTypeOfLocation === 'near_user' &&
    sectionContent?.defaultLocationValue == 'true' &&
    locationPermission == 'denied' &&
    cookieChkbxValue == 'true'
  ) {
    Cookies.set('access_deny', 'AccessDenied', 0, '/');
    showPopupWithTimeout();
  }

  const showPopupWithTimeout = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      uncheck_nearme();
    }, 5000);
    return;
  };

  const hideErrorMessage = () => {
    setShowPopup(false);
    uncheck_nearme();
  };

  useEffect(
    () => () => {
      if (router.asPath.indexOf('#upcomingprograms') > -1) {
        // consoleLog('router.asPath', router, router.asPath, currentUrl);
        setPrograms([]);
        setResultCount(0);
        setRangeStart(0);
      }
    },
    [router.asPath]
  );
  const upcomingProgramsOnmodalClose = () => {
    const pathNameToString = returnHref.split(/[#?]+/);
    return router.push(
      {
        pathname: pathNameToString[0],
        query: pathNameToString[1],
      },
      undefined,
      {
        scroll: false,
      }
    );
  };
  return (
    <>
      <Modal
        isOpen={!!router.query.id}
        onClose={() => {
          upcomingProgramsOnmodalClose();
        }}
        // isOpen
        size="6xl"
        motionPreset="slideInBottom"
        closeOnEsc
        closeOnOverlayClick
        blockScrollOnMount={isMobile ? false : true}
        scrollBehavior={isMobile ? 'outside' : 'inside'}
      >
        <ModalOverlay padding="20px" />
        <ModalContent>
          <ModalCloseButton zIndex="1000" />
          <ModalBody p="0">
            <ProgramDataUI id={router.query.id} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        id="upcomingprograms"
        w="100%"
        // background="url(Quote_pattern-final.jpg') repeat scroll"
        // background={`url('${config.imgPath}/profiles/isha/themes/ishatheme/assets/Quote_pattern-final.jpg') repeat scroll`}
        margin={{ base: '0' }}
        p="0 2%"
        objectFit="contain"
        display="grid"
        // placeContent="center"
        // placeItems="center"
        // marginTop="40px"
        marginBottom="0"
      >
        <SectionTitle titleObj={sectionContent} />
        {/* {sectionContent.body && (
        <StructuredTextParser
          str={render(sectionContent.body)}
          region={region}
          lang={lang}
        />)
      } */}

        {/* ! cards container goes here */}
        <Box w="100%" my="30px">
          {sectionContent?.programTypeList?.json?.length ||
            sectionContent?.searchFilterVisibility ||
            sectionContent?.locationFilterVisibility ||
            sectionContent?.languageFilterVisibility ? (
            <Box w={{ base: '100%' }} mb="25px">
              <Box
                pt={{ sm: '0', md: '10px' }}
                pl={{ base: '15px', md: '0' }}
                fontFamily="FedraSansStd-medium, sans-serif"
                fontSize="14px"
                lineHeight={{
                  base: '35px',
                  md: '20px',
                }}
                textAlign="left"
                display="flex"
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                alignItems="center"
              >
                {(sectionContent?.programTypeList?.json?.length > 0 ||
                  sectionContent?.locationFilterVisibility ||
                  sectionContent?.languageFilterVisibility) && (
                    <Box
                      as="label"
                      fontWeight="400"
                      fontSize="14px"
                      fontFamily="FedraSansStd-book"
                      color="#8a8173"
                      display="grid"
                      placeContent="center"
                    >
                      FILTERS:
                    </Box>
                  )}

                {/* ! location filter goes here */}
                {sectionContent?.locationFilterVisibility &&
                  sectionContent?.locationList?.json?.length ? (
                  <Filters
                    key="location"
                    filterName={center}
                    filterOptions={sectionContent?.locationList?.json}
                    onChange={handleCenterFilter}
                  />
                ) : null}

                {/* ! program type filter goes here */}
                {sectionContent?.programTypeList?.json?.length ? (
                  <Filters
                    key="program"
                    filterName={programFilter}
                    filterOptions={sectionContent?.programTypeList?.json}
                    onChange={handleProgramTypeFilter}
                  />
                ) : null}

                {/* ! language filter goes here */}
                {sectionContent?.languageFilterVisibility &&
                  sectionContent?.languageList?.json?.length ? (
                  <Filters
                    key="language"
                    filterName={lang}
                    filterOptions={sectionContent?.languageList?.json}
                    onChange={handleLangFilter}
                  />
                ) : null}

                {/* ! searchbar goes here */}
                {sectionContent?.searchFilterVisibility ? (
                  <Box
                    width={{ base: 'full', md: 'auto' }}
                    marginLeft={{ base: '0', md: 'auto' }}
                  >
                    <Box
                      position={{ base: 'relative' }}
                      right={{ base: 'inherit', xl: 'inherit' }}
                      top={{ base: 'unset', md: '5px', xl: 'unset' }}
                      padding={{
                        base: '10px 15px !important',
                        sm: '10px 0 !important',
                      }}
                      ml={{ base: 0, sm: '15px !important' }}
                      minWidth={{ base: '250px', md: '275px', xl: '250px' }}
                      borderWidth="0 0 1px 0 !important"
                      fontFamily="FedraSansStd-book"
                      fontSize="16px"
                      borderColor="#CF4523 !important"
                      outline="unset"
                      lineHeight="1.42857143"
                      userSelect="none"
                      w={{ base: 'auto', sm: '95%', lg: 'auto' }}
                      _hover={{
                        color: '#cf4520',
                      }}
                      order={{
                        base: '3',
                        sm: '3',
                        md: '1',
                        xl: '1',
                      }}
                      display={{
                        base: 'inherit',
                        sm: 'inline-block',
                        xl: 'inherit',
                      }}
                    >
                      <Input
                        variant="unstyled"
                        defaultValue={search}
                        key={search}
                        // value={search}
                        pl="5px"
                        ref={searchBoxRef}
                        placeholder={
                          (sectionContent?.programSearchPlaceholder?.json &&
                            sectionContent?.programSearchPlaceholder?.json[0]
                              ?.label) ||
                          'Search ...'
                        }
                        // onChange={handleSearchFilter}
                        // onKeyPress={e => {
                        //   if (e.code == 'Enter') {
                        //     handleSearchFilter(e);
                        //   }
                        // }}
                        onKeyUp={e => {
                          if (e.keyCode === 13) {
                            handleSearchFilter(e);
                          }
                        }}
                        color="#333333"
                        _placeholder={{
                          color: '#333333',
                        }}
                      />
                      <Text
                        as="span"
                        background={`url('${config.imgPath}/d/46272/1663582866-search.svg') no-repeat`}
                        // background="url(search.svg') no-repeat"
                        backgroundSize="auto auto"
                        width="24px"
                        height="24px"
                        cursor="pointer"
                        position="absolute"
                        margin="0"
                        borderWidth="0 !important"
                        float="right"
                        right="0"
                        onClick={e => handleSearchFilter(e)}
                      />
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : null}
          {sectionContent?.defaultTypeOfLocation === 'near_user' &&
            sectionContent?.defaultLocationValue == 'true' && (
              <Box
                as="div"
                textAlign="left"
                marginTop="20px"
                marginBottom="20px"
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                color="#676055"
              >
                <Checkbox
                  borderColor="#CF4520"
                  isChecked={isChecked ? true : false}
                  onChange={handleCheckboxChange}
                >
                  Show programs near me
                </Checkbox>
              </Box>
            )}

          {!programs.length && loading ? (
            <Box margin="20px">
              <Loader key="upcoming-null" pos="relative" />
            </Box>
          ) : null}
          {programs?.length > 0 ? (
            <UpcomingProgramMain
              key="upprog"
              results={programs}
              count={count}
              notFound={sectionContent?.programNotFoundMessage}
              isLeftMenu={isLeftMenu}
              apiType={apiType}
              region={region}
              language={language}
            />
          ) : (
            !loading && (
              <NoUpcomingPrograms
                notFound={sectionContent?.programNotFoundMessage}
              />
            )
          )}
        </Box>
        {/* @{programs?.length}@{resultsCount}@{count}@@ */}
        {programs?.length &&
          programs?.length !== resultsCount &&
          resultsCount > count &&
          resultsCount >= rangeStart + count ? (
          <Box
            pos="relative"
            float="left"
            w="100%"
            //   h={'76px'}
            m="10px auto 30px auto"
            display="block"
            alignItems={'center'}
            justifyContent="center"
          >
            <Box w="100%" textAlign="center" display={'block'}>
              <Link
                bgColor="#cf4520"
                textDecoration="none"
                onClick={handleLoadMore}
                variant={'outline'}
                display="block"
                width="164px"
                p="10px 23px"
                fontWeight="500"
                border="2px solid #cf4520"
                borderRadius="5px"
                color="#fff"
                fontSize="16px"
                fontFamily="FedraSansStd-medium"
                textAlign="center"
                m="0 auto"
                mb="5px"
                background="#D04723"
                _hover={{
                  textDecoration: 'none',
                  background: '#000422',
                  color: '#fff',
                  border: '2px solid transparent',
                }}
                title="Load More"
                rel="next"
              >
                {loadtext}
              </Link>
            </Box>
          </Box>
        ) : null}
      </Box>
      {showPopup ? (
        <Box
          id="access_denied"
          className="access_denied"
          mb="0px"
          position="fixed"
          top={{ base: 'unset', md: 'unset' }}
          bottom={{ base: '20px', md: '20px' }}
          zIndex="100"
          right={{ base: '0', md: 0 }}
          left={{ base: '5%', md: '35%' }}
          transition="all 0.6s ease"
          maxWidth="390px"
          width={{ base: '90%', md: '390px' }}
        >
          <Box
            m="0 auto"
            width={{ base: '90%', md: '390px' }}
            bgColor="#292A2D"
            borderRadius="5px"
            minH="50px"
            display="flex"
            alignItems="center"
          >
            <Box
              fontWeight="400"
              fontFamily="FedraSansStd-book"
              color="#ffffff"
            >
              <Image
                src={`${config.imgPath}/d/46272/1693336389-icon_cross_remove.svg`}
                display="inline-block"
                pl={{ base: '10px', md: '20px' }}
                pr="10px"
              />
              <Box as="span" fontSize="16px" lineHeight="20px">
                Unable to access location
              </Box>
            </Box>
            <Box
              fontWeight="600"
              fontSize={{ base: '13px', md: '14px' }}
              fontFamily="FedraSansStd-book"
              color="#EE491D"
              lineHeight="25px"
              marginLeft="auto"
              marginRight="30px"
              cursor="pointer"
              onClick={e => {
                hideErrorMessage();
              }}
            >
              OK
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#cf4520',
    '&:hover': {
      color: '#fff',
      cursor: 'pointer',
    },
    fontFamily: 'FedraSansStd-book',
  }),
  control: (provided, state) => ({
    ...provided,
    outline: 0,
    border: 0,
    borderBottom: '1px solid #aea493',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
};

const Filters = ({ filterName, onChange, filterOptions }) => {
  return (
    <Box
      maxWidth={{ base: '100%', sm: '190px' }}
      width="100%"
      m="0 6px"
      fontFamily="FedraSansStd-book"
    >
      <Select
        // lineHeight="30px"
        // h="34px"
        // width="190px"
        // p="6px 12px"
        // value={filterName}
        name={filterName}
        minW="121px"
        // onChange={onChange}

        outline="0"
        _focus={{
          outline: '0',
        }}
        maxWidth="170px"
        lineHeight="30px"
        border="0"
        onChange={onChange}
        value={filterOptions.find(obj => obj.value == filterName)}
        styles={customStyles}
        defaultValue={filterName}
        options={filterOptions?.map((el, i) => {
          return {
            value: el?.value,
            label: el?.label,
          };
        })}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#cf4520',
            primary: '#cf4520',
          },
        })}
      >
        <style global jsx>
          {`
            input {
              border: '0px solid !important';
              border: '1px solid #CF4520';
            }
            .chakra-select__wrapper {
              border: 'none !important';
            }
          `}
        </style>
      </Select>
    </Box>
  );
};

const UpcomingProgramMain = ({
  results,
  count,
  notFound,
  isLeftMenu,
  apiType,
  region,
  language,
}) => {
  return results && results?.length > 0 ? (
    // isMobile ? (
    //   <UpcomingProgramMobile
    //     key="upmob"
    //     programs={results}
    //     count={count}
    //     isLeftMenu={isLeftMenu}
    //   />
    // ) : (
    <UpcomingProgramDesktop
      key="updesk"
      programs={results}
      count={count}
      isLeftMenu={isLeftMenu}
      apiType={apiType}
      region={region}
      lang={language}
    />
  ) : (
    // )
    <NoUpcomingPrograms notFound={notFound} />
  );
};

const UpcomingProgramDesktop = ({
  programs,
  count,
  isLeftMenu,
  apiType,
  region,
  lang,
}) => {
  return (
    <SimpleGrid
      spacing="15px"
      gridGap="15px"
      columns={{
        base: 1,
        sm: 1,
        md: isLeftMenu ? 2 : 3,
        lg: isLeftMenu ? 3 : 4,
      }}
    >
      {programs?.length > 0 &&
        programs?.map((program, index) => {
          return (
            <Box
              display="grid"
              gridGap="15px"
              maxW={302}
              width="100%"
              key={index}
              mx="auto"
              _hover={{
                boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)',
              }}
            >
              <UpcomingProgramCard
                key={index}
                number={(index + 1) % count}
                count={count}
                sectionContent={program}
                apiType={apiType}
                region={region}
                lang={lang}
              />
            </Box>
          );
        })}
    </SimpleGrid>
  );
};

const UpcomingProgramMobile = ({ programs, count }) => {
  return (
    <Box display="grid" gridGap="10px" placeItems="center">
      <Box
        w="300px"
        p="10px 0"
        display="flex"
        flexDir="row"
        overflowX="scroll"
        css={{
          '&::-webkit-scrollbar': {
            width: '0',
          },
          '&::-webkit-scrollbar-track': {
            width: '0',
          },
        }}
      >
        {programs?.map((program, index) => {
          return (
            <Box mr="15px">
              <UpcomingProgramCard
                key={index}
                number={(index + 1) % count}
                count={count}
                sectionContent={program}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

/**
 *
 * @param {object} sectionContent
 * @returns {ReactElement} NoUpcomingPrograms data content
 */

const NoUpcomingPrograms = ({ notFound }) => {
  return (
    <Box
      w="100%"
      p="5%"
      textAlign="center"
      display="grid"
      placeContent="center"
      placeItems="center"
      fontFamily="FedraSerifAStdBook"
      fontSize="22px"
      color="#333"
      lineHeight="1.42857143"
    >
      {StructuredTextContent(notFound)}
    </Box>
  );
};

export default UpcomingProgramsV2;
